<template>
  <div class="process_form">
    <el-main>
      <section class="section">
        <h2 class="title">Update Your 2024 Medicare Quote</h2>
        <div class="cart">
          <h3 class="title">Your Progress</h3>
          <span class="num">{{ percentage }}%</span>
          <el-progress :percentage="percentage" :stroke-width="15" :show-text="false"
            define-back-color="rgba(222, 222, 222, 1)" color="rgba(250, 231, 152, 1)"></el-progress>
          <div v-if="step == 0">
            <p class="tips">What is Your Gender?</p>
            <div class="checked">
              <el-button class="checked_btn" :style="form.Gender === 'male'
            ? 'background: rgba(28, 56, 136, 1);color: rgba(255, 255, 255, 1)'
            : 'border: 1px solid rgba(28, 56, 136, 1);color:#000;'
            " @click="() => {
            form.Gender = 'male';
            onNext();
          }
            ">Male</el-button>
              <el-button class="checked_btn" :style="form.Gender === 'Female'
            ? 'background: rgba(28, 56, 136, 1);color: rgba(255, 255, 255, 1);'
            : 'border: 1px solid rgba(28, 56, 136, 1);color:#000;'
            " @click="() => {
            form.Gender = 'Female';
            onNext();
          }
            ">Female</el-button>
            </div>
          </div>

          <div v-if="step == 1">
            <p class="tips">Are You a Homeowner?</p>
            <div class="checked">
              <el-button class="checked_btn" :style="form.isHomeowner == true
            ? 'background: rgba(28, 56, 136, 1);color: rgba(255, 255, 255, 1)'
            : 'border: 1px solid rgba(28, 56, 136, 1);color:#000;'
            " @click="() => {
            form.isHomeowner = true;
            onNext();
          }
            ">YES</el-button>
              <el-button class="checked_btn" :style="form.isHomeowner == false
            ? 'background: rgba(28, 56, 136, 1);color: rgba(255, 255, 255, 1);'
            : 'border: 1px solid rgba(28, 56, 136, 1);color:#000;'
            " @click="() => {
            form.isHomeowner = false;
            onNext();
          }
            ">NO</el-button>
            </div>
          </div>
          <div v-if="step == 2">
            <p class="tips">How Tall Are You?</p>
            <div class="height">
              <el-col :xs="23" :sm="10">
                <el-input name="Height_ft" id="Height_ft" class="carmakestyle" placeholder="Ft."
                  v-model="form.Height_ft" oninput="value = value.replace(/[^\d.]/g,'')"
                  :class="{ errorinput: isheight }" maxlength="6">
                  <template slot="append">ft.</template></el-input>
                <div class="errortext" v-if="isheight">Please enter height</div>
              </el-col>
              <el-col :xs="23" :sm="10">
                <el-input name="Height_in" id="Height_in" class="carmakestyle" placeholder="In."
                  v-model="form.Height_in" oninput="value = value.replace(/[^\d.]/g,'')"
                  :class="{ errorinput: isheight }" maxlength="6">
                  <template slot="append">in.</template></el-input>
              </el-col>
            </div>

            <el-button class="next_btn" style="
                background: rgba(255, 150, 150, 1);
                color: rgba(255, 255, 255, 1);
                margin-top: 30px;
              " @click="onNext">Continue</el-button>
          </div>

          <div v-if="step == 3">
            <p class="tips">How Much Do You Weigh?</p>
            <div class="weight_box">
              <div class="weight">
                <el-input name="weight" id="weight" class="carmakestyle" placeholder="Pleace Input Your Weight"
                  v-model="form.Weight" oninput="value = value.replace(/[^\d.]/g,'')" :class="{ errorinput: isweight }"
                  maxlength="6">
                  <template slot="append" class="weight-lbs">lbs.</template></el-input>
                <div class="errortext" v-if="isweight">Please enter weight</div>
              </div>
            </div>
            <el-button class="next_btn" style="
                background: rgba(255, 150, 150, 1);
                color: rgba(255, 255, 255, 1);
                margin-top: 30px;
              " @click="onNext">Continue</el-button>
          </div>
          <div v-if="step == 4">
            <p class="tips">Do You Use Tobacco?</p>
            <div class="checked">
              <el-button class="checked_btn" :style="form.Smoke === 'Yes'
            ? 'background: rgba(28, 56, 136, 1);color: rgba(255, 255, 255, 1)'
            : 'border: 1px solid rgba(28, 56, 136, 1);color:#000;'
            " @click="() => {
            form.Smoke = 'Yes';
            onNext();
          }
            ">
                YES
              </el-button>
              <el-button class="checked_btn" :style="form.Smoke === 'No'
            ? 'background: rgba(28, 56, 136, 1);color: rgba(255, 255, 255, 1);'
            : 'border: 1px solid rgba(28, 56, 136, 1);color:#000;'
            " @click="() => {
            form.Smoke = 'No';
            onNext();
          }
            ">NO</el-button>
            </div>
          </div>
          <div v-if="step == 5">
            <p class="tips">Do You Have Major Medicare Conditions?</p>
            <div class="checked">
              <el-button class="checked_btn" :style="form.Major_Med_Conditions === 'Yes'
            ? 'background: rgba(28, 56, 136, 1);color: rgba(255, 255, 255, 1)'
            : 'border: 1px solid rgba(28, 56, 136, 1);color:#000;'
            " @click="() => {
            form.Major_Med_Conditions = 'Yes';
            onNext();
          }
            ">YES</el-button>
              <el-button class="checked_btn" :style="form.Major_Med_Conditions === 'No'
            ? 'background: rgba(28, 56, 136, 1);color: rgba(255, 255, 255, 1);'
            : 'border: 1px solid rgba(28, 56, 136, 1);color:#000;'
            " @click="() => {
            form.Major_Med_Conditions = 'No';
            onNext();
          }
            ">NO</el-button>
            </div>
          </div>
          <div v-if="step == 6">
            <p class="tips">Do You Currently Have Medicare Parts A & B?</p>
            <div class="checked">
              <el-button class="checked_btn" :style="form.isInsurance == true
            ? 'background: rgba(28, 56, 136, 1);color: rgba(255, 255, 255, 1)'
            : 'border: 1px solid rgba(28, 56, 136, 1);color:#000;'
            " @click="() => {
            form.isInsurance = true;
            onNext();
          }
            ">YES</el-button>
              <el-button class="checked_btn" :style="form.isInsurance == false
            ? 'background: rgba(28, 56, 136, 1);color: rgba(255, 255, 255, 1);'
            : 'border: 1px solid rgba(28, 56, 136, 1);color:#000;'
            " @click="() => {
            form.isInsurance = false;
            onNext();
          }
            ">NO</el-button>
            </div>
          </div>
          <div v-if="step == 7">
            <p class="tips">What is Your Name?</p>
            <div>
              <el-input name="fiest_name" id="fiest_name" class="carmakestyle" placeholder="First Name"
                v-model="form.firstname" oninput="value = value.trim().charAt(0).toUpperCase() + value.trim().slice(1)"
                :class="{ errorinput: isfirstname }" maxlength="20"></el-input>
              <div class="errortext" v-if="isfirstname">
                Please enter First Name
              </div>
            </div>
            <div style="margin-top: 10px">
              <el-input name="last_name" id="last_name" class="carmakestyle" placeholder="Last Name"
                v-model="form.lastname" oninput="value = value.trim().charAt(0).toUpperCase() + value.trim().slice(1)"
                :class="{ errorinput: islasename }" maxlength="20"></el-input>
              <div class="errortext" v-if="islasename">
                Please enter Last Name
              </div>
            </div>
            <el-button class="next_btn" style="
                background: rgba(255, 150, 150, 1);
                color: rgba(255, 255, 255, 1);
                margin-top: 30px;
              " @click="onNext">Continue</el-button>
          </div>
          <div v-if="step == 8">
            <p class="tips">What is Your Address?</p>
            <!-- <div>
              <el-autocomplete style="width:100%" v-model="form.Address"  id="Address_0" name="Address" class="input_txt" :class="{'errorinput' : isAddress}" :fetch-suggestions="autocompleteSearch" ref="autocomplete" @select="handleSelect"></el-autocomplete>
              <div class="errortext" v-if="isAddress">Address is required</div>
            </div> -->
            <el-row class="optiongroup">
              <el-col :xs="23" :sm="18" class="addressinputright">
                <el-autocomplete name="Address" id="Address_0" class="carmakestyle" placeholder="Address"
                  v-model="form.Address" :class="{ errorinput: isAddress }" :fetch-suggestions="autocompleteSearch"
                  ref="autocomplete" @select="handleSelect"></el-autocomplete>
                <div class="errortext" v-if="isAddress">
                  Address is required
                </div>
              </el-col>
              <el-col :xs="23" :sm="6" class="addressinputleft">
                <el-input name="Unit" id="Unit_0" class="carmakestyle" placeholder="Unit#"
                  v-model="form.Unit"></el-input>
              </el-col>
              <el-col :xs="23" :sm="24" class="addressinput mobaddressinputleft">
                <el-input name="City" id="City_0" class="carmakestyle" placeholder="City" v-model="form.City"
                  :class="{ errorinput: isCity }"></el-input>
                <div class="errortext" v-if="isCity">City is required</div>
              </el-col>
              <el-col :xs="12" :sm="12" class="addressinputright">
                <el-select name="Make" id="Make_0" v-model="form.State" placeholder="Select Other Make"
                  class="carmakestyle" filterable>
                  <el-option :value="''">Select Other Make</el-option>
                  <el-option v-for="i in us_state_map" :key="i.label" :value="i.label">{{ i.value }}</el-option>
                </el-select>
              </el-col>
              <el-col :xs="11" :sm="12" class="addressinputleft">
                <el-input maxlength="5" :class="['carmakestyle', { error_input: isZip }]"
                  oninput="value = value.replace(/[^\d]/g,'')" v-model="form.ZIP" placeholder="ZIP Code">
                </el-input>
                <!-- <el-input name="ZIP" id="Zip_0" class="carmakestyle" placeholder="ZIP" v-model="form.ZIP"
                  :class="{ errorinput: isZip }" maxlength="5" oninput="value = value.replace(/[^\d]/g,'')">
                </el-input> -->
                <div class="errortext" v-if="isZip">
                  Please enter a valid ZIP
                </div>
              </el-col>
            </el-row>
            <el-button class="next_btn md20" style="
                background: rgba(255, 150, 150, 1);
                color: rgba(255, 255, 255, 1);
                margin-top: 30px;
              " @click="onNext">Continue</el-button>
          </div>
          <div v-if="step == 9">
            <p class="tips">What is Your Email?</p>
            <div>
              <el-input v-model="form.Email" name="email" class="input_txt" :class="{ errorinput: isEmail }"></el-input>
              <div class="errortext" v-if="isEmail">
                Please enter a valid email address
              </div>
            </div>
            <div v-loading="emailloading" style="
                width: 130px;
                height: 46px;
                margin: 0px auto;
                margin-top: 30px;
              ">
              <el-button class="next_btn" style="
                  background: rgba(255, 150, 150, 1);
                  color: rgba(255, 255, 255, 1);
                " @click="onNext">
                <span>Continue</span>
              </el-button>
            </div>
          </div>
          <div v-if="step >= 10">
            <p class="tips">What is Your Phone Number?</p>
            <div>
              <el-input v-model="form.phoneNum" name="Number" id="Number_0" placeholder="Phone Number"
                class="input_txt phone_txt" maxlength="10" oninput="value = value.replace(/[^\d]/g,'')"
                :class="{ errorinput: isPhone }" @change="formatPhoneNumber($event)"></el-input>
              <div class="errortext" v-if="isPhone">
                Please enter a valid Phone number
              </div>
            </div>
            <div v-loading="phoneloading" style="
                width: 150px;
                height: 46px;
                margin: 0px auto;
                margin-top: 30px;
              ">
              <el-button class="next_btn" style="
                  background: rgba(255, 150, 150, 1);
                  color: rgba(255, 255, 255, 1);
                  width: 150px;
                " @click="onNext">
                <span>Get Quote</span>
              </el-button>
            </div>
          </div>
          <!-- <div class="error">
            <div class="error_inner">
              <i class="el-icon-check"></i>You could save up to 25% by insuring multiple vehicles
            </div>
          </div> -->
          <span class="back-icon" @click="onBack">
            <img src="@/assets/imgs/back.png" />
          </span>
        </div>
        <div class="content" v-if="step == 7">
          <p>
            By clicking the "Get Quote" button, I provide my electronic
            signature and represent that I am at least 18 and agree to this
            website's <a href="/privacy-statement">Privacy Policy</a> and
            <a href="/terms-of-use">Terms of Use</a>.
          </p>
          <p>
            By clicking the "Get Quote" button, I provide my express written
            consent and authorization to the owner of this website and/or the
            agents of one or more of the listed businesses to contact me for
            marketing/telemarketing purposes at the number and address provided
            above, including my wireless number if provided, using live
            operators, automated telephone dialing systems, artificial voice or
            pre-recorded messages, text messages and/or emails, if applicable,
            even if I have previously registered the provided number on any
            Federal or State Do Not Call Registry. I understand that my consent
            is not required as a condition of purchasing goods or services and
            can be revoked at any time.
          </p>
          <p>
            Message and data rates may apply. Message frequency varies. Reply
            HELP for customer support or STOP to opt-out. See
            <a href="/terms-of-service">SMS Terms of Service</a> for more
            details.
          </p>
        </div>
      </section>

      <div class="recommend">
        <div class="zanzhu">
          <img alt src="@/assets/imgs/wellcare.png" />
          <img alt src="@/assets/imgs/Aetna.png" />
          <img alt src="@/assets/imgs/blue.webp" />
          <img alt src="@/assets/imgs/cigama.png" />
          <img alt src="@/assets/imgs/CVS_Health_logo.png" />
          <img alt src="@/assets/imgs/h.webp" />
          <img alt src="@/assets/imgs/kalser.webp" />
        </div>
      </div>
      <div class="trademark">
        <img src="@/assets/imgs/trademark_1.png" />
        <img src="@/assets/imgs/trademark_2.png" />
        <img src="@/assets/imgs/trademark_3.png" />
      </div>
      <el-dialog :visible.sync="dialogVisible" width="35%" :show-close="false" :before-close="handleClose">
        <div class="dialog_inner">
          <div class="header">
            Wait! View quotes online now.
            <i class="el-icon-circle-close close-icon" @click="handleClose"></i>
          </div>
          <div class="centont">
            <p class="tips">Click to get the best rate.</p>
            <div class="list">
              <div class="item">
                <div class="row">
                  <div class="lf">
                    <img src="@/assets/imgs/selectmypolicy.png" />
                  </div>
                  <div class="rg">
                    <el-button style="
                        background: rgba(255, 130, 130, 1);
                        color: rgba(255, 255, 255, 1);
                      ">
                      <a style="text-decoration: none; color: inherit" v-pre
                        href="https://viajandocontenis.com/6b42ee25-9a1d-4e55-ae71-de9952682830?ad_id={{ad.id}}&adset_id={{adset.id}}&campaign_id={{campaign.id}}&ad_name={{ad.name}}&adset_name={{adset.name}}&campaign_name={{campaign.name}}&source={{site_source_name}}&placement={{placement}}&fbclid=fbclid">
                        View My Quote
                      </a>
                    </el-button>
                  </div>
                </div>
                <p class="txt">
                  <a style="text-decoration: none; color: inherit" v-pre
                    href="https://viajandocontenis.com/6b42ee25-9a1d-4e55-ae71-de9952682830?ad_id={{ad.id}}&adset_id={{adset.id}}&campaign_id={{campaign.id}}&ad_name={{ad.name}}&adset_name={{adset.name}}&campaign_name={{campaign.name}}&source={{site_source_name}}&placement={{placement}}&fbclid=fbclid">
                    Seeking the Perfect Medicare?
                  </a>
                </p>
              </div>
              <div class="item">
                <div class="row">
                  <div class="lf">
                    <img src="@/assets/imgs/McAfee.png" />
                  </div>
                  <div class="rg">
                    <el-button style="
                        background: rgba(255, 191, 0, 1);
                        color: rgba(255, 255, 255, 1);
                      ">
                      <a style="text-decoration: none; color: inherit" v-pre href="https://viajandocontenis.com/f2ef3302-f245-4907-9373-6a4bd8f20b79?a
                                        d_id={{ad.id}}&adset_id={{adset.id}}&campaign_id={{campaign.id}}&ad_name=
                                        {{ad.name}}&adset_name={{adset.name}}&campaign_name={{campaign.name}}&sour
                                        ce={{site_source_name}}&placement={{placement}}&fbclid=fbclid">View My Quote
                      </a>
                    </el-button>
                  </div>
                </div>
                <p class="txt">
                  <a style="text-decoration: none; color: inherit" v-pre
                    href="https://viajandocontenis.com/6b42ee25-9a1d-4e55-ae71-de9952682830?ad_id={{ad.id}}&adset_id={{adset.id}}&campaign_id={{campaign.id}}&ad_name={{ad.name}}&adset_name={{adset.name}}&campaign_name={{campaign.name}}&source={{site_source_name}}&placement={{placement}}&fbclid=fbclid">
                    Affordable Medicare Quotes
                  </a>
                </p>
              </div>
              <!-- <div class="item">
                <div class="row">
                  <div class="lf">
                    <img src="@/assets/imgs/64fb64061163b7e9497bd3b1.png" />
                  </div>
                  <div class="rg">
                    <el-button style="background: rgba(255, 130, 130, 1);color: rgba(255, 255, 255, 1);"> View My Quote
                    </el-button>
                  </div>
                </div>
                <p class="txt">Lowest Rates in NV</p>
              </div> -->
            </div>
          </div>
        </div>
      </el-dialog>
    </el-main>
  </div>
</template>

<script>
// import '@/assets/js/loadJs'
import {
  ContactUsFrom,
  VFun,
  UpUsFrom,
  PostFrom,
  PingFrom,
  getTransactionId,
} from "@/api/index";
import { Encrypt, Decrypt, deepCopy } from "@/plugins/jessas";
import { Select, Option, Loading, Autocomplete, Dialog } from "element-ui";
import dayjs from "dayjs";
import tz from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
dayjs.extend(tz);

Vue.use(Select);
Vue.use(Option);
Vue.use(Autocomplete);
Vue.use(Dialog);
Vue.use(Loading.directive);
Vue.prototype.$loading = Loading.service;
export default {
  name: "ProcessForm",
  components: {},
  data() {
    return {
      form: {
        isInsurance: false,
        isHomeowner: false,
        Address: "",
        Email: "",
        phoneNum: "",
        firstname: "",
        lastname: "",
        Unit: "",
        City: "",
        State: "Alabama",
        ZIP: "",
        Gender: "male",
        Smoke: false,
        Weight: "",
        Height_ft: "",
        Height_in: "",
        Major_Med_Conditions: false,
      },
      step: 0,
      islasename: false,
      isfirstname: false,
      isweight: false,
      isheight: false,
      isAddress: false,
      isEmail: false,
      isPhone: false,
      isCity: false,
      isZip: false,
      MyAdd: "",
      percentage: 12,
      dialogVisible: false,
      numistrue: false,
      useruuid: "",
      predictions: [],
      emailloading: false,
      us_state_map: [
        { label: "AL", value: "Alabama" },
        { label: "AK", value: "Alaska" },
        { label: "AZ", value: "Arizona" },
        { label: "AR", value: "Arkansas" },
        { label: "CA", value: "California" },
        { label: "CO", value: "Colorado" },
        { label: "CT", value: "Connecticut" },
        { label: "DE", value: "Delaware" },
        // {
        //   label:
        //     // DC,value: "District Of Columbia"},{label:
        //     FL,
        //   value: "Florida",
        // },
        { label: "GA", value: "Georgia" },
        { label: "HI", value: "Hawaii" },
        { label: "ID", value: "Idaho" },
        { label: "IL", value: "Illinois" },
        { label: "IN", value: "Indiana" },
        { label: "IA", value: "Iowa" },
        { label: "KS", value: "Kansas" },
        { label: "KY", value: "Kentucky" },
        { label: "LA", value: "Louisiana" },
        { label: "ME", value: "Maine" },
        { label: "MD", value: "Maryland" },
        { label: "MA", value: "Massachusetts" },
        { label: "MI", value: "Michigan" },
        { label: "MN", value: "Minnesota" },
        { label: "MS", value: "Mississippi" },
        { label: "MO", value: "Missouri" },
        { label: "MT", value: "Montana" },
        { label: "NE", value: "Nebraska" },
        { label: "NV", value: "Nevada" },
        { label: "NH", value: "New Hampshire" },
        { label: "NJ", value: "New Jeresy" },
        { label: "NM", value: "New Mexico" },
        { label: "NY", value: "New York" },
        { label: "NC", value: "North Carolina" },
        { label: "ND", value: "North Dakota" },
        { label: "OH", value: "Ohio" },
        { label: "OK", value: "Oklahoma" },
        { label: "OR", value: "Oregon" },
        { label: "PA", value: "Pennsylvania" },
        { label: "RI", value: "Rhode Island" },
        { label: "SC", value: "Southcarolina" },
        { label: "SD", value: "South Dakota" },
        { label: "TN", value: "Tennessee" },
        { label: "TX", value: "Texas" },
        { label: "UT", value: "Utah" },
        { label: "VT", value: "Vermont" },
        { label: "VA", value: "Virginia" },
        { label: "WA", value: "Washington" },
        { label: "WV", value: "West Virginia" },
        { label: "WI", value: "Wisconsin" },
        { label: "WY", value: "Wyoming" },
      ],
      timer: null,
      phoneloading: false,
      renum: "",
    };
  },
  async created() {
    const step = localStorage.getItem("step");
    if (step) {
      // this.step = parseInt(step)
      this.step = parseInt(step) <= 9 ? parseInt(step) : 9;
    }
    window.localStorage.setItem("lastTime", new Date().getTime());
    window.document.onmousedown = function () {
      window.localStorage.setItem("lastTime", new Date().getTime());
    };
    this.timer = window.setInterval(() => this.checkTimeout(), 10000);
    await this.loadJS(
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyBBalfcMGTRGRdbeJT2LGvAjxQSRfCPtLQ&libraries=places"
    );
    await this.loadJS("/js/loadJs.js");
    const percentage = localStorage.getItem("percentage");
    if (percentage) {
      this.percentage = percentage;
    } else {
      this.percentage = 12;
      localStorage.setItem("percentage", this.percentage);
    }
    this.isfrom();
  },
  mounted() {
    const _this = this;
    const cardfrom = localStorage.getItem("cardfrom");
    if (cardfrom) {
      const temp = { ...JSON.parse(cardfrom), ...this.form };
      this.form = temp;
    } else {
      this.$router.push({ path: "/" }).catch(() => { });
    }
    this.isfrom();
    this.generateUniqueId();
  },
  beforeDestroy() {
    if (this.timer) {
      window.clearInterval(this.timer);
    }
  },
  methods: {
    checkTimeout() {
      // 更新当前时间
      const currentTime = new Date().getTime();
      const lastTime = window.localStorage.getItem("lastTime");
      // 判断是否超时
      if (currentTime - lastTime > 30 * 1000) {
        const isShow = window.localStorage.getItem("isShow");
        if (!isShow) {
          this.dialogVisible = true;
          window.localStorage.setItem("isShow", 1);
        }
        if (this.timer) {
          window.clearInterval(this.timer);
        }
      }
    },

    loadJS(url) {
      return new Promise((resolve) => {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        // IE
        if (script.readyState) {
          script.onreadystatechange = () => {
            if (
              script.readyState == "loaded" ||
              script.readyState == "complete"
            ) {
              script.onreadystatechange = null;
              resolve();
            }
          };
        } else {
          // 其他浏览器
          script.onload = function () {
            resolve();
          };
        }
        document.getElementsByTagName("head")[0].appendChild(script);
      });
    },
    handleClose() {
      this.dialogVisible = false;
    },
    onBack() {
      this.step = localStorage.getItem("step");
      this.step -= 1;
      if (this.step < 0) {
        this.$router.go(-1);
      }
      if (this.percentage >= 12) {
        this.percentage -= 8;
        localStorage.setItem("cardfrom", JSON.stringify(this.form));
        localStorage.setItem("percentage", this.percentage);
        localStorage.setItem("step", this.step);
      }
    },
    async onNext() {
      if (this.step == 0) {
        if (this.form.Gender === "") {
          return;
        }
      }
      if (this.step == 1) {
        if (this.form.isHomeowner === "") {
          return;
        }
      }
      if (this.step == 2) {
        if (!this.form.Height_ft || !this.form.Height_in) {
          this.isheight = true;
          return;
        } else {
          this.isheight = false;
        }
      }
      if (this.step == 3) {
        if (!this.form.Weight) {
          this.isweight = true;
          return;
        } else {
          this.isweight = false;
        }
      }
      if (this.step == 4) {
        if (!this.form.Smoke) {
          return;
        }
      }
      if (this.step == 5) {
        if (!this.form.Major_Med_Conditions) {
          return;
        }
      }
      if (this.step == 7) {
        this.whatname();
        if (this.isfirstname) {
          return;
        }
        if (this.islasename) {
          return;
        }
      }
      if (this.step == 8) {
        if (!this.addaddress()) {
          return;
        }
      }
      if (this.step == 9) {
        await this.valEmail();
        if (this.isEmail) {
          return;
        }
      }
      if (this.step == 10) {
        await this.fromcontinue()
        if (this.isPhone) {
          return;
        }
      }
      this.percentage = 12 + 8 * (this.step + 1);
      localStorage.setItem("cardfrom", JSON.stringify(this.form));
      localStorage.setItem("percentage", this.percentage);
      this.step += 1;
      localStorage.setItem("step", this.step);
      // await this.upuserdata();
      if (this.step >= 11) {
        const loading = this.$loading({
          lock: true,
          text: "",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        const _this = this;
        window.setTimeout(() => {
          loading.close();
          _this.$router.push({ path: "/Result" }).catch(() => { });
        }, 2000);
        this.submitToLeed();
        EF.conversion({
          offer_id: 1668,
          amount: 0.01,
        });
      } else {
        await this.upuserdata();
      }
    },
    whatname() {
      this.isfirstname = false;
      this.islasename = false;
      if (!this.form.firstname && !this.form.lastname) {
        this.isfirstname = true;
        this.islasename = true;
      } else if (!this.form.firstname) {
        this.isfirstname = true;
      } else if (!this.form.lastname) {
        this.islasename = true;
      } else {
        this.isfirstname = false;
        this.islasename = false;
      }
      var pattern_En = new RegExp("[A-Za-z]");
      if (!this.isfirstname) {
        if (pattern_En.test(this.form.firstname)) {
          this.isfirstname = false;
        } else {
          this.isfirstname = true;
        }
      }
      if (!this.islasename) {
        if (pattern_En.test(this.form.lastname)) {
          this.islasename = false;
        } else {
          this.islasename = true;
        }
      }
    },
    addaddress() {
      const card12 = [!this.form.Address, !this.form.City, !this.form.ZIP];
      if (card12.indexOf(true) === -1) {
        this.isAddress = false;
        this.isCity = false;
        this.isZip = false;
        return true;
      } else {
        this.form.Address ? (this.isAddress = false) : (this.isAddress = true);
        this.form.City ? (this.isCity = false) : (this.isCity = true);
        this.form.ZIP ? (this.isZip = false) : (this.isZip = true);
        this.form.ZIP.length != 5 ? (this.isZip = true) : (this.isZip = false);
        return false;
      }
    },
    formatPhoneNumber(phoneNumberString) {
      this.renum = phoneNumberString;
      var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
      var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (!cleaned || cleaned.length < 10) {
        this.isPhone = true;
        this.numistrue = false;
      } else if (match) {
        this.isPhone = false;
        var intlCode = match[1] === undefined ? "+1 " : "";
        this.form.phoneNum = [intlCode, match[0]].join("");
        this.numistrue = true;
      } else {
        this.isPhone = true;
        this.numistrue = false;
      }
    },
    async fromcontinue() {
      if (this.isPhone) {
        return;
      }
      this.phoneloading = true;
      const res = await VFun({
        data: Encrypt(
          this.form.phoneNum + ",2841e22ed3754f98aa7c36d7a3848ec1,phone,dsfsdf"
        ),
      });
      console.log(Decrypt(res).split(",")[1], this.form.phoneNum);
      if (Decrypt(res).split(",")[1] === "True") {
        this.isPhone = false;
        this.numistrue = true;
        let subfrom = JSON.parse(localStorage.getItem("usercardfrom"));
        if (!subfrom) {
          subfrom = {
            name: "",
            phone: "",
          };
        }
        subfrom.name = this.form.firstname + " " + this.form.lastname;
        subfrom.phone = this.form.phoneNum;
        subfrom.other = this.form;
        subfrom.other.date = dayjs()
          .tz("America/New_York")
          .format("YYYY/MM/DD HH:mm:ss");
        if (this.numistrue) {
          const res = await UpUsFrom(subfrom);
        }
      } else {
        this.isPhone = true;
      }
      this.phoneloading = false;
    },
    async valEmail() {
      this.emailloading = true;
      const chexkemail = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
      if (!this.form.Email || !chexkemail.test(this.form.Email)) {
        this.isEmail = true;
      } else {
        const res = await VFun({
          data: Encrypt(
            this.form.Email + ",2841e22ed3754f98aa7c36d7a3848ec1,email,dsfsdf"
          ),
        });
        console.log(res)

        console.log(Decrypt(res).split(",")[1])
        if (Decrypt(res).split(",")[1] === "False") {
          this.isEmail = true;
        } else {
          this.isEmail = false;
        }
      }
      this.emailloading = false;
    },
    // 更新表单数据
    async upuserdata() {
      let subfrom = JSON.parse(localStorage.getItem("usercardfrom"));
      const cookie = document.cookie.split(";");
      if (!subfrom) {
        subfrom = {
          other: {
            date: dayjs().tz("America/New_York").format("YYYY/MM/DD HH:mm:ss"),
          },
        };
      }
      const userip = JSON.parse(localStorage.getItem("userip"));
      subfrom.other = this.form;
      subfrom.other.date = dayjs()
        .tz("America/New_York")
        .format("YYYY/MM/DD HH:mm:ss");
      subfrom.other.IP_Address = userip?.ip;
      subfrom.web_id = 45;
      subfrom.category_id = 4790;
      subfrom.other.leadid = leadid;
      subfrom.other.id = 20773;
      subfrom.other.step = this.step;
      subfrom.name =
        this.form.firstname && this.form.lastname
          ? this.form.firstname + " " + this.form.lastname
          : subfrom.name;
      const res = await UpUsFrom(subfrom);
      localStorage.setItem("usercardfrom", JSON.stringify(res.data));
    },
    generateUniqueId() {
      if (!localStorage.getItem("useruuid")) {
        const timestamp = new Date().getTime();
        const random = Math.random().toString(36).substring(2, 10);
        this.useruuid = timestamp.toString(36) + random;
        localStorage.setItem("useruuid", this.useruuid);
        this.Initializedata();
      } else {
        this.useruuid = localStorage.getItem("useruuid", this.useruuid);
        // this.Initializedata();
      }
    },
    // 进入表单发送生成请求
    async Initializedata() {
      const subfrom = {};
      const userip = JSON.parse(localStorage.getItem("userip"));
      subfrom.other = this.form;
      subfrom.web_id = 45;
      subfrom.category_id = 4790;
      subfrom.other.date = dayjs()
        .tz("America/New_York")
        .format("YYYY/MM/DD HH:mm:ss");
      subfrom.other.ip = userip?.ip;
      subfrom.other.ipcity = userip?.city;
      subfrom.other.leadid = leadid;
      subfrom.other.timezone = userip?.timezone;
      subfrom.name = this.useruuid
        ? this.useruuid
        : localStorage.setItem("useruuid", this.useruuid);
      subfrom.other.useruuid = this.useruuid
        ? this.useruuid
        : localStorage.setItem("useruuid", this.useruuid);
      const res = await ContactUsFrom(subfrom);
      localStorage.setItem("usercardfrom", JSON.stringify(res));
    },

    autocompleteSearch(queryString, cb) {
      if (!queryString) {
        this.predictions = [];
        return;
      }
      const autocompleteService = new google.maps.places.AutocompleteService();
      autocompleteService.getPlacePredictions(
        {
          input: queryString,
          language: "en",
          componentRestrictions: { country: "US" },
        },
        (predictions) => {
          this.predictions = [];
          predictions.forEach((e) => {
            this.predictions.push({
              value: e.description,
            });
          });
          cb(this.predictions);
          // this.predictions = predictions || [];
          // cb(predictions.map((prediction) => prediction.description));
        }
      );
    },
    handleSelect(item) {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address: item.value }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK && results[0]) {
          const address = results[0];
          console.log(address);
          const streetNumber = address.address_components.find((component) =>
            component.types.includes("street_number")
          );
          const route = address.address_components.find((component) =>
            component.types.includes("route")
          );
          const locality =
            address.address_components.find((component) =>
              component.types.includes("locality")
            ) ||
            address.address_components.find((component) =>
              component.types.includes("administrative_area_level_2")
            );
          const administrativeArea = address.address_components.find(
            (component) =>
              component.types.includes("administrative_area_level_1")
          );
          const postalCode = address.address_components.find((component) =>
            component.types.includes("postal_code")
          );
          const neighborhood = address.address_components.find((component) =>
            component.types.includes("neighborhood")
          );
          this.form.Address = `${streetNumber ? streetNumber.long_name : ""} ${route ? route.long_name : ""
            }${neighborhood ? " , " + neighborhood.long_name : ""}`;
          // this.form.City = locality ? locality.long_name : "";
          // this.form.State = administrativeArea && administrativeArea.short_name;
          // this.form.ZIP = postalCode && postalCode.long_name;

          this.$set(this.form, "City", locality ? locality.long_name : "");

          this.$set(
            this.form,
            "State",
            administrativeArea && administrativeArea.short_name
          );
          this.$set(this.form, "ZIP", postalCode && postalCode.long_name);
        }
      });
    },
    isfrom() {
      if (localStorage.getItem("percentage")) {
        this.percentage = localStorage.getItem("percentage") - 0;
      }
      if (localStorage.getItem("cardfrom")) {
        this.form = JSON.parse(localStorage.getItem("cardfrom"));
      }
    },
    async submitToLeed() {
      const cookie = document.cookie.split(";");
      const leads = cookie.filter((el) => el.includes("leadid_token"));

      const LeadiD_Token = leads[0].split("=")[1];

      const submitData = {
        Key: "d386b9000e697fd7a4b4b133c09d31e5cfe604bed743be6ea498f01cc5529f82",
        API_Action: "pingPostLead",
        TYPE: 24,
        SRC: "brightsight_med",
        Landing_Page: "https://findmedicarechoice.com/",
        Sub_ID: 12,
        Insurance_Type: "Medicare Supplement Plan",
        Return_Best_Price: 1,
        // Project: "Carpenters",
        LeadiD_Token,
        Trusted_Form_URL: `https://api.trustedform.com/${window.trustedForm.token}`,
        TCPA_Consent: "Yes",
        TCPA_Language: "English",
        State: "AK",
        TCPAText:
          "TCPA (Telephone Consumer Protection Act) Compliance Statement:By providing your contact information, you consent to receive calls, text messages, and/or emails from us or our authorized representatives regarding products and services. This consent applies to both landline and mobile phone numbers. Please note that your consent is not a condition of purchasing any products or services. If you wish to opt out of receiving further communications, you can do so by contacting us directly or using the unsubscribe option provided in our communications.We respect your privacy and adhere to all applicable TCPA regulations. Your information will not be shared with any third parties for marketing purposes without your explicit consent.If you have any questions or concerns regarding our TCPA compliance or the use of your personal information, please contact us.",
      };
      const userip = JSON.parse(localStorage.getItem("userip"));

      submitData.IP_Address = userip?.ip;
      submitData.First_Name = this.form.firstname;
      submitData.Last_Name = this.form.lastname;
      submitData.Gender = this.form.Gender;
      submitData.City = this.form.City;
      submitData.State = this.form.State;
      submitData.Email = this.form.Email;
      submitData.Primary_Phone = this.form.phoneNum;
      submitData.Currently_Insured =
        this.form.isInsurance === true ? "Yes" : "No";
      submitData.Medicare_Part_A_B =
        this.form.isInsurance === true ? "Yes" : "No";
      submitData.DOB = `${this.form.month}/${this.form.day}/${this.form.year}`;
      submitData.Weight = this.form.Weight;
      submitData.Tobacco_Use = this.form.Smoke;
      submitData.Major_Med_Conditions = this.form.Major_Med_Conditions;
      submitData.Zip = this.form.ZIP;
      submitData.Address = this.form.Address;
      submitData.Homeowner = this.form.isHomeowner ? "Yes" : "No";
      // console.log("submitData", submitData);

      const data = {
        // Test_Lead: "1",
        Request: {
          Mode: "ping",

          Key: submitData.Key,
          API_Action: submitData.API_Action,
          Return_Best_Price: submitData.Return_Best_Price,
          TYPE: submitData.TYPE,
          IP_Address: submitData.IP_Address,
          Landing_Page: submitData.Landing_Page,
          Insurance_Type: submitData.Insurance_Type,
          SRC: submitData.SRC,
          Sub_ID: submitData.Sub_ID,
          State: submitData.State,
          TCPAText: submitData.TCPAText,
          LeadiD_Token: submitData.LeadiD_Token,
          Currently_Insured: submitData.Currently_Insured,
          Medicare_Part_A_B: submitData.Medicare_Part_A_B,
          DOB: submitData.DOB,
          Gender: submitData.Gender,
          Tobacco_Use: submitData.Tobacco_Use,
          Major_Med_Conditions: submitData.Major_Med_Conditions,
          Zip: submitData.Zip,
          Project: submitData.Project,
          Address: submitData.Address,
          Trusted_Form_URL: submitData.Trusted_Form_URL,
          TCPA_Consent: submitData.TCPA_Consent,
          TCPA_Language: submitData.TCPA_Language,
          Homeowner: submitData.Homeowner,
          City: submitData.City,
        },
      };
      // console.log(data);
      const res = await PingFrom(JSON.stringify(data));
      // const transaction_id = await getTransactionId();
      // console.log(transaction_id);

      //解析status
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(res.data, "text/xml");
      const status =
        xmlDoc.getElementsByTagName("status")[0]?.childNodes[0]?.nodeValue;
      const pirce =
        xmlDoc.getElementsByTagName("peice")[0]?.childNodes[0]?.nodeValue;
      if (status && status === "Matched") {
        //获得leadid
        const leadId =
          xmlDoc.getElementsByTagName("lead_id")[0]?.childNodes[0]?.nodeValue;
        //发送POST模式
        await PostFrom(
          JSON.stringify({
            Test_Lead: "1",
            Request: { Lead_ID: leadId, ...submitData, Mode: "post" },
          })
        );
        //提交成功ping和post成功信息到ADmax

        UpUsFrom({
          web_id: 45,
          category_id: 9999,
          id: JSON.parse(localStorage.getItem("usercardfrom")).id,
          name: submitData.First_Name + " " + submitData.Last_Name,
          phone: submitData.Primary_Phone,
          other: {
            Lead_ID: leadId,
            ...submitData,
            Status: status,
            date: dayjs().tz("America/New_York").format("YYYY/MM/DD HH:mm:ss"),
            pirce: pirce,
            step: "done",
            afclickid: localStorage.getItem("afclickid"),
            // ...transaction_id,
          },
        });
      } else {
        //提交ping不成功信息到ADmax
        UpUsFrom({
          web_id: 45,
          category_id: 9000,
          id: JSON.parse(localStorage.getItem("usercardfrom")).id,
          name: submitData.First_Name + " " + submitData.Last_Name,
          phone: submitData.Primary_Phone,
          other: {
            ...submitData,
            Status: status,
            date: dayjs().tz("America/New_York").format("YYYY/MM/DD HH:mm:ss"),
            pirce: pirce,
            step: "done",
            afclickid: localStorage.getItem("afclickid"),
            // ...transaction_id,
          },
        });
      }
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/layout.scss";

.process_form {
  margin-top: 70px;

  .el-main {
    padding: 0px;
    overflow: hidden;
    background: rgba(247, 247, 247, 1);

    .section {
      min-height: 800px;

      .title {
        font-weight: 900;
        font-size: 35px;
        text-align: center;
        line-height: 4;
        color: rgba(255, 150, 150, 1);
      }

      .num {
        font-size: 20px;
        font-weight: 700;
        display: block;
        text-align: center;
        color: rgba(255, 150, 150, 1);
        margin: 0px auto 10px auto;
      }

      .cart {
        width: 25%;
        min-height: 410px;
        background: rgba(255, 255, 255, 1);
        border-radius: 15px;
        box-shadow: 0px 0px 19px rgba(199, 199, 199, 0.4);
        margin: 0px auto;
        padding: 20px 5%;
        text-align: center;
        position: relative;

        .title {
          color: #000;
          font-size: 20px;
          font-weight: 700;
          text-align: center;
          line-height: 2;
        }

        .tips {
          font-size: 28px;
          font-weight: 700;
          text-align: center;
          margin: 30px 20px 20px 20px;
        }

        .checked {
          padding: 25px 0px;

          .checked_btn {
            width: 106px;
            height: 50px;
            font-size: 16px;
            font-weight: 400;
            margin: 0px 20px;
            color: #000;
          }

          .checked_btn:hover {
            background: rgba(28, 56, 136, 1);
            color: rgba(255, 255, 255, 1) !important;
          }
        }

        .next_btn {
          background: rgba(255, 150, 150, 1);
          color: rgba(255, 255, 255, 1);
          width: 128px;
          height: 46px;
          font-size: 20px;
          font-weight: 700;
        }

        .md20 {
          margin-bottom: 20px;
        }

        .optiongroup {
          margin-top: 10px;
          overflow: hidden;

          .addressinputright {
            padding-right: 15px;
            margin-bottom: 2rem;
          }

          .mobaddressinputleft {
            margin-bottom: 2rem;
            width: 100%;
          }

          .carmakestyle {
            width: 100%;
          }
        }

        .weight_box {
          display: flex;
          justify-content: center;

          .weight {
            width: 70%;

            .el-input-group__append {
              color: #000;
            }
          }

          .errortext {
            width: 70%;
          }
        }

        .height {
          display: flex;
          flex-direction: row;
          justify-content: space-around;

          .el-input-group__append {
            color: #000;
          }
        }

        .phone_txt {
          .el-input__inner {
            text-align: center;
          }
        }

        .error {
          margin-top: 20px;
          height: 35px;
          border-radius: 10px;
          border: 1px solid rgba(255, 150, 150, 1);

          .error_inner {
            height: 31px;
            line-height: 31px;
            border-radius: 8px;
            margin: 2px;
            border: 1px solid rgba(255, 150, 150, 1);
          }
        }

        .errorinput {
          .el-input__inner {
            border: 1px solid #eb737a;
          }
        }

        .errortext {
          color: #eb737a;
          padding: 0px;
          height: 25px;
          line-height: 25px;
          text-align: left;
          font-size: 14px;
        }
      }

      .content {
        width: 50%;
        margin: 40px auto 100px auto;
        border-radius: 25px;
        padding: 20px 3%;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 19px rgba(199, 199, 199, 0.4);

        p {
          color: rgba(108, 99, 100, 1);
          font-size: 14px;

          a {
            color: #2f9ee9;
          }
        }
      }

      .back-icon {
        position: absolute;
        top: 30px;
        left: 30px;
        cursor: pointer;
      }
    }

    .recommend {
      text-align: center;

      .r_content {
        font-size: 16px;
        color: rgba(28, 56, 136, 1);
        font-weight: 700;
        padding: 10px 0px;
      }

      .zanzhu {
        width: 100%;
        background: rgba(255, 255, 255, 1);
        background-repeat: no-repeat;
        background-size: cover;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
        text-align: center;

        img {
          vertical-align: middle;
          margin: 20px 30px;
          width: 110px;
        }
      }
    }

    .trademark {
      text-align: center;
      margin: 30px 0px;

      img {
        padding: 50px;
      }
    }
  }

  .el-dialog {
    border-radius: 15px;
  }

  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    padding: 0;
  }

  .dialog_inner {
    height: 450px;
    background: rgba(255, 150, 150, 1);
    border-radius: 15px;

    .header {
      height: 55px;
      line-height: 55px;
      text-align: center;
      color: rgba(255, 255, 255, 1);
      font-size: 20px;
      position: relative;

      .close-icon {
        position: absolute;
        right: 20px;
        top: 10px;
        cursor: pointer;
        font-size: 25px;
      }
    }

    .centont {
      margin: 20px;
      padding: 10px;
      background: rgba(255, 255, 255, 1);
      border-radius: 15px;

      .tips {
        margin-left: 10px;
        margin-bottom: 10px;
        line-height: 2;
        color: #0076f6;
      }

      .list {
        height: 300px;
        overflow-y: scroll;
        padding: 0px 10px;

        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          background-color: #fff;
        }

        &::-webkit-scrollbar-thumb {
          background: rgba(255, 150, 150, 1);
          border-radius: 15px;
          max-height: 45px;
        }

        .item {
          text-align: center;
          border: 1px solid rgba(255, 130, 130, 1);
          margin-bottom: 20px;
          padding: 25px 10px;
          border-radius: 15px;

          .row {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .lf {
              img {
                width: 130px;
              }
            }
          }

          .txt {
            margin-top: 15px;
          }

          .txt:hover {
            color: #0076f6;
          }
        }
      }
    }
  }
}

.is-fullscreen .el-loading-spinner {
  background-image: url("../assets/loading.png");
  background-repeat: no-repeat;
  background-size: 150px 150px;
  height: 150px;
  width: 100%;
  background-position: center;
  top: 40%;
  transform: rotate(0deg);
  animation: path 5s linear infinite;
}

@keyframes path {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.is-fullscreen .el-loading-spinner .el-icon-loading,
.is-fullscreen .el-loading-spinner .el-loading-text {
  display: none;
}

.el-loading-mask.is-fullscreen {
  background-color: transparent;
}

@media screen and (max-width: 1300px) {
  .process_form {
    .el-main {
      .section {
        .cart {
          width: 45%;
          min-height: 420px;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .process_form {
    .el-main {
      .section {
        .title {
          font-size: 35px;
          margin-top: 20px;
        }

        .cart {
          width: calc(70% - 20px);
          margin: 20px auto;
          padding: 10px 10px;
          min-height: 400px;

          .optiongroup {
            .addressinputright {
              padding-right: 15px;
            }

            .addressinputleft {
              margin-bottom: 2rem;
            }

            .mobaddressinputleft {
              width: 100%;
            }

            .addressinputleft:last-child {
              padding-left: 15px;
            }
          }

          .back-icon {
            top: 38px;
          }
        }

        .content {
          width: 90%;
        }
      }

      .recommend {
        .zanzhu {
          background: rgba(255, 255, 255, 1);
        }
      }

      .trademark {
        img {
          padding-bottom: 10px;
        }
      }
    }

    .dialog_inner {
      height: 450px;
      background: rgba(255, 150, 150, 1);
      border-radius: 15px;

      .header {
        height: 55px;
        line-height: 55px;
        text-align: center;
        color: rgba(255, 255, 255, 1);
        font-size: 20px;
        position: relative;

        .close-icon {
          position: absolute;
          right: 20px;
          top: 10px;
          cursor: pointer;
          font-size: 25px;
        }
      }
    }
  }

  .el-dialog {
    width: 80% !important;

    .tips {
      color: #2440b3;
    }
  }
}

@media screen and (max-width: 768px) {
  .process_form {
    .el-main {
      .section {
        min-height: 700px;

        .title {
          font-size: 26px;
          line-height: 1;
        }

        .cart {
          width: calc(96% - 20px);
          margin: 20px auto;
          padding: 10px 10px 20px 10px;
          min-height: 400px;

          .optiongroup {
            .addressinputright {
              padding-right: 0px;
            }

            .addressinputleft {
              margin-bottom: 2rem;
            }

            .mobaddressinputleft {
              width: 95.55%;
            }

            .addressinputleft:last-child {
              padding-left: 15px;
            }
          }

          .back-icon {
            top: 38px;
          }
        }

        .content {
          width: 90%;
        }
      }

      .recommend {
        .zanzhu {
          background: rgba(255, 255, 255, 1);
        }
      }

      .trademark {
        img {
          padding-bottom: 10px;
        }
      }
    }

    .dialog_inner {
      height: 450px;
      background: rgba(255, 150, 150, 1);
      border-radius: 15px;

      .header {
        height: 55px;
        line-height: 55px;
        text-align: center;
        color: rgba(255, 255, 255, 1);
        font-size: 20px;
        position: relative;

        .close-icon {
          position: absolute;
          right: 20px;
          top: 10px;
          cursor: pointer;
          font-size: 25px;
        }
      }
    }
  }

  .el-dialog {
    width: 80% !important;
  }
}

@media screen and (max-width: 767px) {
  .process_form .dialog_inner .centont .list .item .row {
    display: block;
  }

  .height {
    display: flex;

    .el-col {
      width: 40%;
    }
  }

  .weight_box {
    .weight {
      width: 80% !important;
    }

    .errortext {
      width: 80% !important;
    }
  }
}
</style>
